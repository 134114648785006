$default-font: "Montserrat", sans-serif;
$accent: rgba(107, 106, 106, 0.7);

$base_darkblue: #21324c;
$base_blue: #2CA3DE;

$text: $base_darkblue;

$base-unit: 7px;

$font-size: $base-unit * 2.25; //18

$padding-xs: $base-unit / 2; //4
$padding-s: $base-unit; // 8
$padding-m: $base-unit * 2; //16
$padding-l: $base-unit * 3; //24
$padding-xl: $base-unit * 4; //32
$padding-xxl: $base-unit * 5; //40

$margin-xs: $base-unit / 2; //4
$margin-s: $base-unit; // 8
$margin-m: $base-unit * 2; //16
$margin-l: $base-unit * 3; //24
$margin-xl: $base-unit * 4; //32
$margin-xxl: $base-unit * 5; //40

$white: #ffffff;

$links: $text;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$max-xs: 0;
$max-sm: 575px;
$max-md: 767px;
$max-lg: 991px;
$max-xl: 1199px;
$max-xxl: 1360px;
$max-xxxl: 2000px;
$max-4k: 2560px;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1440px);

$gutter: $grid-gutter-width;

/* Colors */
$header-text: #22324c;
$header-text-hover: #2ca3de;
$color-fonts: #21324c;
$color-link: #2CA3DE;
$color-company: #5ec718;
$color-gray: #999999;

$btn-hover: #62D31A;

/* Dark Section */
$bg-dark-section: #21324c;
$bg-dark-font: #fff;
$bg-main-color: #2CA3DE;

/* Top Nav Menu */
$color-bg: #fff;
$color-btn-font: #fff;
$color-btn-bg: #2CA3DE;
$color-goto-btn: #D8D8D8;
$color-goto-btn-active: #2CA3DE;

/* Section layouts paddings */
$section-padding: 100px 0;
$section-padding-top: 0 0 100px 0;

/* Numbers */
$numbers-bg: #273A56;
$numbers-color-font: #fff;

/* Borders */
$border-color-main: #dddddd;
$border-radius-base: 4px;
