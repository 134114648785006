@import "../abstracts/vars";

* {
  overflow-anchor: none;
}

.content-wrapper {
  padding: $section-padding;
}

ba-tabs__circles {

  .mat-tab-labels {
    display: flex;
    justify-content: space-between;
  }

  &.circles {
    &__small-xs {
      .mat-tab-label {
        width: 185px;
        height: 245px;

        .tab-media_wrapper {
          width: 120px;
          height: 120px;


          .tab-icon {
            transform: scale(0.6);
          }
        }
        .tab-horizontal-line {
          margin-top: 60px;
        }
      }
    }

    &__small {
      .mat-tab-label {
        width: 215px;
        height: 250px;

        .tab-media_wrapper {
          width: 140px;
          height: 140px;


          .tab-icon {
            transform: scale(0.8);
          }
        }
        .tab-horizontal-line {
          margin-top: 70px;
        }
        .tab-title h3 {
          margin-top: 20px;
        }
      }
    }

    &__lg {
      .mat-tab-label {
        width: 310px;
        height: 315px;

        .tab-media_wrapper {
          width: 200px;
          height: 200px;
        }
        .tab-horizontal-line {
          margin-top: 100px;
        }
      }
    }
  }

  .mat-tab-label {
    opacity: 1;
    align-items: flex-start;

    .mat-tab-label-content {
      display: flex;
      align-items: flex-start;

      min-width: 100%;
      white-space: normal;
    }

    &:first-child,
    &:last-child {
      .tab-horizontal-line { display: none; }
    }
  }

  .mat-tab-label-active {

    .tab-media_wrapper {
      background: #2CA3DE;

      .tab-icon {
        background-position: 100px 0px !important;
      }
    }
  }

  .mat-tab-body-wrapper {

    border-radius: 20px;
    background-color: #f7f7f7;
    color: #2ca3de;
    min-height: 65px;

    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;

    @media (max-width: $max-sm) {
      font-size: 16px;
    }
  }

  .mat-tab-body-content {
    padding: 30px 40px;
    transform: none !important;

    @media (max-width: $max-sm) {
      padding: 20px;
    }
  }

  .mat-ink-bar {
    bottom: -24px;
    height: 42px;
    background: url("/../../assets/img/tabs/triangle.svg") center no-repeat;
  }



  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    flex: 1 0 5%;

    .mat-ripple-element {
      display: none;
    }

    .mat-tab-header-pagination-chevron {
      height: 15px;
      width: 15px;
    }
  }
  .mat-tab-label-container {
    margin: 0;
    flex: 0 1 auto;
    width: 100%;
  }
}
ba-tabs__vertical-panel {

  .mat-tab-group {
    flex-direction: row;

    @media (max-width: $max-lg) {
      flex-direction: column;
    }
  }

  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }

  .mat-tab-header {
    flex: 0 1 422px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-image: linear-gradient(132deg, #456183, #21324c 100%);

    @media (max-width: $max-lg) {
      flex: 0 1 auto;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 0px;
    }
  }
  .mat-tab-body-wrapper {
    flex: 1;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    @media (max-width: $max-lg) {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 6px;
    }
  }

  .mat-tab-labels {
    flex-direction: column;
    align-items: flex-start;
  }

  .mat-tab-label {
    opacity: 1;
    width: 100%;
    height: 100%;
    padding: 28px 0;
    justify-content: flex-start;

    .mat-tab-label-content {
      white-space: normal;
    }

    &:not(:last-child) {
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }
  }

  .tab-icon {
    background-position: -40px 0 !important;
    background-repeat: no-repeat !important;
  }

  .mat-tab-label-active {
    .tab-icon {
      background-position: 0px 0px !important;
    }

    .tab-title {
      color: #2ca3de;
    }

    &:after {
      content: '';
      background-color: #2ca3de;
      position: absolute;
      width: 10px;
      height: 100%;
    }
  }



  .mat-ink-bar,
  .mat-ripple-element {
    display: none;
  }
}


.siema-technologies{
  text-align: center;

  ngx-siema-slide {
    flex-direction: column;
  }
}
