@import "../abstracts/vars";

.services-tech {
  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &--1 {
    }

    &--3 {
      [class*='__cell'] {
        max-width: 380px;
      }

      [class*='__cover'] {
        justify-content: space-between;

        @media (max-width: 385px) {
          justify-content: center;
        }
      }
    }

    &--1,
    &--3 {
      margin-left: -40px;

      @media (max-width: 1360px) {
        justify-content: center;
      }
    }

    &--tabble-1 {
      justify-content: center;

      & table {
        & th {
          text-align: center;
        }

        & td {
          padding-top: 30px;

          &:nth-child(2) {
            text-align: right;
          }
        }
      }
    }
    &--tabble-2 {
      margin-left: -40px;

      @media (max-width: 1400px) {
        justify-content: center;
      }

      [class*='__table'] {
        margin-left: 40px;
      }
    }
  }

  &__cell {
    margin: 40px 0 0 40px;
    border-radius: 20px;
    border: 2px solid $border-color-main;
    width: 100%;
  }

  &__name {
    text-align: center;
    font-weight: 600;
    padding: 18px 30px;
    line-height: 1.33;
    border-bottom: 2px solid $border-color-main;
  }

  &__cover {
    padding: 5px 15px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__item {
    min-width: 160px;
    text-align: center;
    margin: 25px 0 25px 0;
  }

  &__value {
    margin-top: 20px;
  }

  &__table {
    margin-top: 40px;
    width: 100%;
    max-width: 610px;

    &.full { max-width: 100%; }

    & table {
      width: 100%;
      font-size: 1.111rem;

      & col:first-child {
        width: 260px;

        @media (max-width: $max-md) {
          width: 160px;
        }
      }

      & th {
        font-weight: 600;
        padding: 0 0 15px;
        border-bottom: 2px solid $border-color-main;
      }

      & td {
        padding-top: 20px;

        &:first-child {
          color: $color-gray;
        }

        &:nth-child(2) {
          padding-left: 25px;
        }
      }

      & tr:first-child {
        td {
          padding-top: 30px;
        }
      }
    }
  }

  &__row {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 2px solid $border-color-main;
    border-radius: 20px;

    &-cell {
      width: 16.666%;
      min-height: 132px;
      display: flex;
      justify-content: center;
      align-content: center;
      border-left: 2px solid $border-color-main;

      &:first-child {
        border-left: none;
      }

      @media (max-width: $max-xl) {
        width: 33.333%;

        &:nth-child(4) {
          border-left: none;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          border-top: 2px solid $border-color-main;
        }
      }

      @media (max-width: $max-md) {
        width: 50%;

        &:nth-child(4) {
          border-left: 2px solid $border-color-main;
        }

        &:nth-child(3),
        &:nth-child(5) {
          border-left: none;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          border-top: 2px solid $border-color-main;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 140px;
      min-height: 52px;
      margin: 10px;
    }
  }
}
