@import "styles/abstracts/vars";
@import "styles/_libs";
@import "styles/base/layouts";
@import "styles/base/fonts";
@import "styles/base/buttons";

@import "styles/_mixins/title";
@import "styles/_mixins/block";

@import "styles/components/tech-stack";

@import "~ngx-smart-modal/ngx-smart-modal";

* {
  box-sizing: border-box;
}

::placeholder {
  color: #666;
}

::selection {
  background-color: $accent;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 3.33333rem;
  line-height: 1.2166;
}

h2 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.111;

  @media (max-width: $max-lg) {
    font-size: 1.8rem;
  }
}

h3 {
  font-weight: 600;
  font-size: 1.9444rem;
  line-height: 1;
}

h4 {
  font-weight: 600;
  font-size: 1.666rem;
  line-height: 1.47;
}

html {
  min-height: 100%;
  height: 100%;
  font-size: $font-size;
  -webkit-tap-highlight-color: transparent;

  scroll-behavior: smooth;

  @media (max-width: $max-lg) {
    &.noscroll {
      overflow: hidden;
    }
  }

  @media (max-width: $max-sm) {
    font-size: 16px;
  }
}

body {
  min-height: 100%;
  height: 100%;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow-x: hidden;
  color: $text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: $max-lg) {
    &.noscroll {
      overflow: visible;
      height: 100%;
    }
  }
}

a, button, input, select, textarea {
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
}

.img-fluid {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

a {
  color: $color-link;

  &:visited {
    text-decoration: none;
    color: $color-link;
    transition: color 0.2s ease, background-color 0.2s ease, background 0.2s ease, border 0.2s ease, outline 0.2s ease, opacity 0.2s ease-in-out;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }


  & .link {
    color: inherit;
  }
}

b, strong {
  font-weight: bold;
}

.container {
  padding: 0 70px;

  @media (max-width: $max-xl) {
    padding: 0 50px;
  }

  @media (max-width: $max-lg) {
    padding: 0 50px;
  }

  @media (max-width: $max-md) {
    padding: 0 25px;
  }

  @media (max-width: $max-sm) {
    padding: 0 15px;
  }
}

.read-more {
  margin-top: 16px;
  display: inline-block;
  position: relative;
  color: $white;
  font-family: $default-font;
  font-weight: 600;
  padding-right: 20px;
  transition: padding 300ms;

  &:before {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;
    content: '';
    display: inline-block;
    height: 13px;
    width: 8px;
    background: url("assets/img/icons/icon-read-more.svg") center no-repeat;
  }

  &:visited {
    color: $white;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.mt-0 {
  margin-top: 0 !important;
}

@import "styles/base/media"; // Always at the end


.nsm-content {
  padding: 40px 40px 0 40px;
}

.nsm-body {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #21324c;

  h3 {
    font-size: 35px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #1f3049;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}


.ng5-slider {
  margin: 15px 0 70px !important;

  .ng5-slider-tick:first-of-type .ng5-slider-tick-legend {
    left: 22px;

    @media (max-width: $max-sm) {
      left: 0;
    }
  }

  .ng5-slider-tick-legend {
    top: 40px !important;
    font-size: 14px;
    max-width: none !important;
    width: 68px;

    @media (max-width: $max-sm) {
      width: 62px;
    }
  }

  .ng5-slider-pointer {
    width: 44px !important;
    height: 44px !important;
    top: -20px !important;
    border-radius: 50% !important;
    background-image: linear-gradient(315deg, #2ca3de, #53a0fd 48%, #62d31a);


    &:after {
      width: 22px !important;
      height: 22px !important;
      top: 11px !important;
      left: 11px !important;
      border-radius: 50% !important;
      background: #fff !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }

    &.ng5-slider-active:after {
      background-color: #fff;
    }
  }

  :focus {
    outline: none;
  }
}


ba-about-us ba-title-bar .title-bar {
  min-height: 400px !important;


  &__title {
    padding: 170px 0 20px 0;
  }
}


@media (max-width: 390px) {
  #hubspot-messages-iframe-container {
    border-top-left-radius: 5px;
    background: rgba(32, 45, 65, 0.3);
  }
}

.lazyload,
.lazyloading {
  opacity: 0.3;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 200ms;
}

.tile__description a {
  color: #5ec718;
}
