@import "../abstracts/vars";

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: $color-btn-bg;
  border-color: $color-btn-bg;
}
.btn-primary.focus, .btn-primary:focus {
  box-shadow: none;
}

.btn {
  cursor: pointer;
  display: inline-block;
  padding: 16px 15px 15px;
  font-size: .8888rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: $color-btn-font;
  background: $color-btn-bg;
  border-radius: 4px;
  transition: background-color 300ms, color 300ms;
  outline: none;
  border-width: 0;

  &:visited {
    color: $color-btn-font;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }

  &:hover {
    text-decoration: none;
    color: $color-btn-font;
    background: $btn-hover;
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    color: $color-btn-font;
    background: #999;
    border-color: #999;
  }

  @media (max-width: 1199px) {
    margin-top: 25px;
    position: static;
  }
}

.btn--dark {
  background: $color-fonts;
  border: 3px solid $color-fonts;

  &:hover {
    background-color: $white;
    border-color: $color-fonts;
    color: $color-fonts;
  }
}

.btn--white-bd {
  background: transparent;
  border: 3px solid $white;

  &:hover {
    background-color: $white;
    border-color: $white;
    color: $color-fonts;
  }
}
